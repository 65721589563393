<template>
	<Toast />
	<div id="layout-config" :class="containerClass">
		<!-- 		<a href="#" class="layout-config-button" id="layout-config-button" @click="toggleConfigurator">
			<i class="pi pi-cog"></i>
		</a> -->
		<a href="#" class="layout-config-close" @click="hideConfigurator">
			<i class="pi pi-times"></i>
		</a>

		<div class="layout-config-content">			
			<h6>翻译和期刊设置</h6>
			<Divider layout="horizontal" align="center">
				<span class="p-tag">翻译设置</span>
			</Divider>
			<div class="p-inputgroup">
				<Button label="翻译" class="p-button-primary" />
				<Dropdown v-model="ori_lang" @update:modelValue="changeOriLang" :options="ori_lang_values"
					optionLabel="name" placeholder="Select" />
				<Button icon='pi pi-sort-alt' class="p-button-text" style="transform: rotate(90deg);" @click="changeLangPair" />
				<Dropdown v-model="tag_lang" @update:modelValue="changeTargetLang" :options="tag_lang_values"
					optionLabel="name" placeholder="Select" />
			</div>
			
			<h6>学科词典</h6>
			<WordFilterInput ref='tmpMajor' :show_list_copy='false' :filter_input_id_copy="'select_tmp_major_for_search'" :op_status_copy="'1'" v-if='majors.length!==0' :list_copy='majors'
				:selected_words_copy='selected_major_name' />
				
			<div style="margin-top: 40px;">
				<h6 title='关闭后将不启用本应用的划词翻译功能'>划词翻译</h6>
				<InputSwitch title='关闭后将不启用本应用的划词翻译功能' v-model="select_translate" @change="changeSelectTranslate" />
				
				<h6 title='点击段落右上角按钮复制文本时默认选择的内容'>论文段落复制设置<span style="background-color: red;font-weight: 700;color: #FFFFFF;padding:2px;border-radius:5px;margin-left: 10px;">New</span></h6>
				<div title='复制文本时默认选择的内容' class="p-grid">
					<div class="p-col-12 p-md-4" title='复制文本时选择原文'>
						<div class="p-field-radiobutton">
							<RadioButton id="option1" name="option" value="ori_text" @change="changeCopyRadioValue" v-model="copy_radio_value" />
							<label for="option1">原</label>
						</div>
					</div>
					<div class="p-col-12 p-md-4" title='复制文本时选择译文'>
						<div class="p-field-radiobutton">
							<RadioButton id="option2" name="option" value="trans_text" @change="changeCopyRadioValue" v-model="copy_radio_value" />
							<label for="option2">译</label>
						</div>
					</div>
					<div class="p-col-12 p-md-4" title='复制文本时同时选择原文和译文'>
						<div class="p-field-radiobutton">
							<RadioButton id="option3" name="option" value="both_text" @change="changeCopyRadioValue" v-model="copy_radio_value" />
							<label for="option3">双</label>
						</div>
					</div>
				</div>
				
				<h6 title='适用于某些中文文献空格过多的情况,开启后复制内容时将清除文本间空格'>复制时清除空格<span style="background-color: red;font-weight: 700;color: #FFFFFF;padding:2px;border-radius:5px;margin-left: 10px;">New</span></h6>
				<InputSwitch title='开启后复制内容时将清除文本间空格' v-model="clear_space" @change="changeClearSpace" />
			</div>
			<Divider layout="horizontal" align="center" style="margin-top: 20px;">
				<span class="p-tag">布局设置</span>
			</Divider>
			<div title='关闭后将不在文献阅读时显示段落解析结果,增大PDF文档的显示空间' style="margin-top: 40px;">
				<h6>显示阅读栏<span style="background-color: red;font-weight: 700;color: #FFFFFF;padding:2px;border-radius:5px;margin-left: 10px;">New</span></h6>
				<InputSwitch v-model="show_read_para" @change="changeShowReadPara" />
			</div>
			<Divider layout="horizontal" align="center" style="margin-top: 20px;">
				<span class="p-tag">期刊设置</span>
			</Divider>
			
			<div class="p-inputgroup">
				<Button label="文章来源" class="p-button-primary" />
				<Dropdown v-model="select_papers_source" @update:modelValue="changePapersSource" :options="papers_source"
					optionLabel="name" placeholder="Select" />
			</div>

			<div class='card-gray' style="overflow-x: auto;width: 100%;max-height: 200px;overflow-y: auto;">
				<div style="display: flex;flex-direction: row;align-items: center;justify-content:space-between;">
					<Button label='编辑来源' icon="pi pi-pencil" class="p-button-text" @click="changeShowListStatus" />
					<Button :disabled='!show_edit_journal' label="保存" icon="pi pi-cloud-upload" class="p-button-text" @click="saveSelectJournals" />
				</div>
				<div class="translation-item" v-for="(item, j) of select_journals" :key="j">
					<div style="color: var(--primary-color);padding: 3px;" v-if='show_edit_journal'>
						<i class="pi pi-trash" style="margin-left: 5px;" @click="deleteJournal(item)" />
					</div>
					<div style="margin: 0 10px 0 10px;padding-right: 10px;">{{item}}</div>
					<!-- <div style="margin-left: 10px;" >测绘科学与技术</div> -->
				</div>
			</div>
			
			<div v-if='show_edit_journal' style="margin-bottom: 10px;">可选列表</div>
			<WordFilterInput ref='selectJournal' @addJournal='addJournal' :filter_input_id_copy="'select_journal'" :op_status_copy="'2'" v-if='ori_journals.length!==0 && show_edit_journal' :list_copy='journals'
				:selected_words_copy="''" />
		</div>
	</div>
</template>

<script>
	import MajorService from './service/MajorService.js';
	import NetWorkService from './service/NetWorkService.js';
	import WordFilterInput from './pages/userComponents/WordFilterInput.vue';
	export default {
		emits: ['layoutChange','layoutColorChange'],
		props: {
			layoutMode: {
				type: String,
				default: null
			},
			layoutColorMode: {
				type: String,
				default: null
			}
		},
		data() {
			return {
				all_journals:[],
				show_read_para:true,
				select_translate:false,
				show_edit_journal:false,
				active: false,
				d_layoutMode: this.layoutMode,
				d_layoutColorMode: this.layoutColorMode,
				majors: [],
				// journals:[],
				ori_journals:[],
				select_journals:[],
				ori_lang_values: [{
						name: '中文',
						code: 'zh-cn'
					},
					{
						name: '英文',
						code: 'en'
					},
				],
				tag_lang_values: [{
						name: '中文',
						code: 'zh-cn'
					},
					{
						name: '英文',
						code: 'en'
					},
				],
				papers_source: [{
						name: '云端',
						code: 'cloud'
					},
					{
						name: '个人',
						code: 'local',
					},
				],
				copy_radio_value:null,
				clear_space:false,
			}
		},
		watch: {
			$route() {
				if (this.active) {
					this.active = false;
					this.unbindOutsideClickListener();
				}
			},
			layoutMode(newValue) {
				this.d_layoutMode = newValue;
			},
			layoutColorMode(newValue) {
				this.d_layoutColorMode = newValue;
			}
		},
		components: {
			'WordFilterInput': WordFilterInput,
		},
		outsideClickListener: null,
		MajorService: null,
		created() {
			this.MajorService = new MajorService();
			this.networkService = new NetWorkService();
		},
		mounted() {
			this.getLocalStore();
			this.select_journals=this.$appState.user_info.select_journals;
			this.copy_radio_value=this.$appState.copy_radio_value;
			this.clear_space=this.$appState.clear_space;
			this.MajorService.getMajorsNameAndId().then(data =>{
				this.majors = data;
			});
			this.MajorService.getJournalNameAndId().then(data =>{
				console.log('journal_data',data);
				this.ori_journals=data;
				this.all_journals=data;
				this.flushJournals(0);
			});
		},
		methods: {
			changeSelectTranslate(){
				// console.log('当前状态',this.select_translate);
				this.$appState.select_translate=this.select_translate;
				if (this.select_translate==true){
					localStorage.setItem('select_translate', '1');
				}else{
					localStorage.setItem('select_translate', '0');
				}
			},
			changeShowReadPara(){
				// console.log('当前状态',this.select_translate);
				this.$appState.show_read_para=this.show_read_para;
			},
			changeClearSpace(){
				this.$appState.clear_space=this.clear_space;
			},
			changeShowListStatus(){
				this.show_edit_journal=!this.show_edit_journal;
				if (this.$appState.select_papers_source.code=='local'){
					this.getAllTopics();
				}else{
					this.getJournalsOfUser();
				}
			},
			getJournalsOfUser(){
				this.ori_journals= this.all_journals;
				this.select_journals=this.$appState.user_info.select_journals;
				this.flushJournals(1);
				// this.MajorService.getJournalNameAndId().then(data =>{
				// 	this.ori_journals= data;
				// 	this.select_journals=this.$appState.user_info.select_journals;
				// 	this.flushJournals(1);
				// 	// console.log('修改为云端来源',this.$appState.user_info.select_journals);
				// });
			},
			showError(msg){
				this.$toast.add({
					severity: 'error',
					summary: '失败',
					detail: msg,
					life: 3000
				});
			},
			async getAllTopics(){				
				var data = new URLSearchParams();
				var url = '/pdf/search-papers-topics';
				// console.log("请求开始");
				var resp = await this.networkService.postService(url, data);
				if(resp.code==200){
					if (resp.data.all_topics.length==0){
						this.showError('没有用户文献,不能选择此来源');
						this.changePapersSource(this.papers_source[0]);
					}
					
					this.select_journals=resp.data.select_topics;
					this.ori_journals=resp.data.all_topics;
					this.flushJournals(1);
				}
			},
			async saveSelectJournals(){
				if(this.select_journals.length==0){
					return;
				}
				
				var data = new URLSearchParams();
				
				if (this.$appState.select_papers_source.code=='cloud'){
					data.append("select_journals", JSON.stringify(this.select_journals));
					var url = '/member/change-select-journals';
				}else{
					data.append("select_topics", JSON.stringify(this.select_journals));
					url = '/member/change-select-topics';
				}
				// console.log("请求开始");
				var resp = await this.networkService.postService(url, data);
				if(resp.code==200){
					this.$toast.add({
						severity: 'success',
						summary: '保存成功',
						detail: '保存选择来源成功，将用于图文检索',
						life: 2000
					});
					this.show_edit_journal=false;
					this.$appState.user_info.select_journals=this.select_journals;
				}else{
					this.showError('保存选择来源失败，请稍后再试');
					this.show_edit_journal=false;
				}
			},
			flushJournals(op){
				var this_journals=[];
				for (var i=0;i<this.ori_journals.length;i++){
					var journal_name=this.ori_journals[i];
					var pure_journal=this.getPureJournal(journal_name);
					//不在列表内才显示
					if (this.select_journals.indexOf(pure_journal)==-1){
						this_journals.push(journal_name);
					}
				}
				// console.log('新列表长度',this_journals.length);
				this.journals=this_journals;
				if (op==1 && this.show_edit_journal==true){
					this.$refs.selectJournal.list=this_journals;
					this.$refs.selectJournal.InitialWords();
				}
				// return this_journals;
			},
			deleteJournal(journal_name){
				if (this.select_journals.length==1){
					this.$toast.add({
						severity: 'error',
						summary: '删除失败',
						detail: '至少需要选择一个对象',
						life: 3000,
					});
					return;
				}
				var journal_index=this.select_journals.indexOf(journal_name);
				this.select_journals.splice(journal_index,1);
				this.flushJournals(1);
			},
			getPureJournal(journal_name){
				if(this.$appState.select_papers_source.code=='cloud'){
					var pure_journal=journal_name.split(' ')[0];
				}else{
					pure_journal=journal_name;
				}
				return pure_journal;
			},
			addJournal(journal_name){
				var pure_journal=this.getPureJournal(journal_name);
				this.select_journals.unshift(pure_journal);
				this.flushJournals(1);
			},
			getLocalStore(){				
				var source_code=this.getStoreSetting(this.papers_source,'paper_source_code');
				if (source_code!=null){
					this.$appState.select_papers_source=source_code;
				}
				
				if (this.$appState.select_papers_source.code=='local'){
					this.getAllTopics();
				}
	
				var tag_lang=this.getStoreSetting(this.tag_lang_values,'tag_lang_code');
				if (tag_lang!=null){
					this.$appState.tag_lang=tag_lang;
				}
				
				var ori_lang=this.getStoreSetting(this.tag_lang_values,'ori_lang_code');
				if (ori_lang!=null){
					this.$appState.ori_lang=ori_lang;
				}
				
				if(localStorage.getItem('tmp_major')!=null){
					this.selected_major_name=localStorage.getItem('tmp_major');
					this.$appState.tmp_major=localStorage.getItem('tmp_major');
				}else{
					this.selected_major_name=this.$appState.user_info.major;
					this.$appState.tmp_major=this.$appState.user_info.major;
				}
				
				if(localStorage.getItem('select_translate')!=null){
					if(localStorage.getItem('select_translate')=='1'){
						this.select_translate=true;
					}else{
						this.select_translate=false;
					}
				}else{
					this.select_translate=true;
				}
				this.$appState.select_translate=this.select_translate;

			},
			getStoreSetting(options,item_name){
				//返回选中的设置
				for (var i=0;i<options.length;i++){
					if (options[i].code==localStorage.getItem(item_name)){
						return options[i];
					}
				}
				return null
			},
			// //刷新专业
			flushMajor(){
				// this.selected_major_name=this.$appState.user_info.major;
				this.$refs.tmpMajor.selected_words=this.$appState.user_info.major;
				// console.log('刷新专业',this.selected_major_name);
			},
			//修改文章源
			changePapersSource(value) {
				this.$appState.select_papers_source = value;
				localStorage.setItem('paper_source_code', value.code);
				if (value.code=='local'){
					this.getAllTopics();
				}else{
					this.getJournalsOfUser();
				}
			},
			toggleConfigurator() {
				this.active = !this.active;
				// event.preventDefault();

				// if (this.active)
				// 	this.bindOutsideClickListener();
				// else
				// 	this.unbindOutsideClickListener();
			},
			hideConfigurator(event) {
				//将不符合major的改为'未标准'
				this.$refs.tmpMajor.recorrectTmpMajor();
				
				this.active = false;
				this.unbindOutsideClickListener();
				event.preventDefault();
			},
			changeMajor(value) {
				// console.log(value);
				this.$appState.major = value;
				this.$appState.second_class = value.items[0];
			},
			changeSecondClass(value){
				this.$appState.second_class = value;
			},
			changeLangPair(){
				var old_ori_value=this.$appState.ori_lang;
				this.changeOriLang(this.$appState.tag_lang);
				this.changeTargetLang(old_ori_value);
			},
			changeOriLang(value) {
				this.$appState.ori_lang = value;
				localStorage.setItem('ori_lang_code', value.code);
			},
			changeTargetLang(value) {
				this.$appState.tag_lang = value;
				localStorage.setItem('tag_lang_code', value.code);
			},
			// changeInputStyle(value) {
			// 	this.$appState.inputStyle = value;
			// },
			// changeRipple(value) {
			// 	this.$primevue.config.ripple = value;
			// },
			// changeLayout(event, layoutMode) {
			// 	this.$emit('layout-change', layoutMode);
			// 	event.preventDefault();
			// },
			// changeLayoutColor(event, layoutColor) {
			// 	this.$emit('layout-color-change', layoutColor);
			// 	event.preventDefault();
			// },
			bindOutsideClickListener() {
				if (!this.outsideClickListener) {
					this.outsideClickListener = (event) => {
						if (this.active && this.isOutsideClicked(event)) {
							this.active = false;
						}
					};
					document.addEventListener('click', this.outsideClickListener);
				}
			},
			unbindOutsideClickListener() {
				if (this.outsideClickListener) {
					document.removeEventListener('click', this.outsideClickListener);
					this.outsideClickListener = null;
				}
			},
			isOutsideClicked(event) {
				return !(this.$el.isSameNode(event.target) || this.$el.contains(event.target));
			},
			//改变状态时写入缓存
			changeCopyRadioValue(){
				localStorage.setItem('copy_radio_value', this.copy_radio_value);
				this.$appState.copy_radio_value = this.copy_radio_value;
			}
		},
		computed: {
			containerClass() {
				return ['layout-config', {
					'layout-config-active': this.active
				}];
			},
			rippleActive() {
				return this.$primevue.config.ripple;
			},
			inputStyle() {
				return this.$appState.inputStyle;
			},
			//初始化目标语言
			ori_lang() {
				// console.log('源语言',this.$appState.ori_lang);
				return this.$appState.ori_lang;
			},
			//初始化目标语言
			tag_lang() {
				// console.log('源语言',this.$appState.tag_lang);
				return this.$appState.tag_lang;
			},
			//文章来源
			select_papers_source() {
				return this.$appState.select_papers_source;
			}
		}
	}
</script>

<style>
	.translation-item {
		display: flex;
		align-items: center;
		border-top: 1px solid #ededed;
		padding: 2px 0px 2px 0px;
	}
</style>