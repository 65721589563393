<template>
	<Toast />
	<Utils ref='Utils' />
	<div class='card-gray' v-if='whole_file==1 && from_page_name!="managePapers"'>
		<Button label="退出" icon="pi pi-upload" class="p-button-danger" @click="goBack" title="返回来源页" />
	</div>
	<EditWord @reflushProduts="reflushProduts" @initialProduct='initialProduct' ref='editWord' :product_copy="product"
		:showDialog='productDialog' :disabled_or='true' v-if='productDialog' />
	<pageNumInput @hidePaperReader='goBack' v-show="read_single_pdf" :input_box_id="'pdf_page_num_input'"
		ref='changeSinglePDF' :op_type_copy='2' @getNeighborPdf='flushPdfPath' @startSearch='getTargetPage' />
	<!-- <Button label="返回原页" style="width: 150px;margin-bottom: 5px;margin-top: 2px;"  class="p-button-info p-button-outlined" @click="goBack" /> -->
	<div class="card p-grid p-fluid" height='100%'>
		<iframe v-if='show_para' ref='pdfjs_viewer_1' class='card p-col-12 p-md-8' name="pdf_container"
			id="pdf_container" :src="'pdfjs-legacy-latest/web/viewer.html?file='+pdf_path" frameborder="0" width="100%"
			:height="pdf_height"></iframe>
		<iframe v-if='!show_para' ref='pdfjs_viewer_2' class='card p-col-12 p-md-12' name="pdf_container"
			id="pdf_container" :src="'pdfjs-legacy-latest/web/viewer.html?file='+pdf_path" frameborder="0" width="100%"
			:height="pdf_height"></iframe>
		<div class="p-col-12 p-md-4" v-if='show_para'>
			<div class="card-gray">
				<div class="p-inputgroup" id="search_box">
					<span class="p-input-icon-right">
						<InputText autocomplete="off" id="search_input" style="width: 100%;" :value="select_word"
							v-on:keyup.enter="searchWord" @focusin="focusIn" type="text" @input="InputBlur"
							placeholder="查单词<(￣︶￣)↗[GO!]" />
						<i class="pi pi-times" style="opacity: 0.7;" @click.stop="InitialWords" title='清空输入框' />
					</span>
					<span class="p-inputgroup-addon" @click="searchWord">
						<i class="pi pi-search" title='查单词'></i>
					</span>
				</div>
			</div>
			<div class="card-gray" id="word_container" v-if='word_trans===true' :style="trans_style">
				<h6 style="vertical-align: middle;" @click="changeShowWord">{{show_word_list?'收起':'展开'}}单词列表 (｡･∀･)ﾉﾞ<i
						class="pi pi-angle-down" style="float: right;"></i></h6>
				<div class="word-list" id='list-box' v-if='show_word_list'>
					<div style="height: 138px;overflow: auto;overflow-x:hidden;">
						<ul class="p-autocomplete-items" v-for="(item, j) of filter_list" :key="j">
							<p class="word-item" @click="selectItem($event, item)" style="padding: 3px 3px 3px 8px;"
								v-html="item"></p>
						</ul>
					</div>
				</div>

				<div class='card'>
					<div class="translation-item"
						style="font-weight: 700;padding-bottom: 5px;background-color:#f8f9fa;">
						<div class='p-col-4 title-item'>原文</div>
						<div class='p-col-4 title-item'>释义<i class="pi pi-pencil" @click="editWord" title="编辑当前单词释义"
								style="color: var(--primary-color);"></i></div>
						<!-- <div class='p-col-2'>引用</div> -->
						<div class='p-col-4 title-item'>来源<i v-if='from_personal' class="pi pi-refresh" title="从词库中重新查询"
								@click="searchFromDics" style="color: var(--primary-color);"></i></div>
					</div>
					<div class="word-item translation-item"
						style="border-top:1px solid #ededed;padding: 2px 0px 2px 0px;" v-for="(item, j) of products"
						:key="j">
						<div class='p-col-4'>{{word.or}}</div>
						<div class='p-col-4'>{{item.w}}</div>
						<!-- <div class='p-col-2'>{{item.ref}}</div> -->
						<div v-if='item.mjid_from.length==1' class='p-col-4'>{{item.mjid_from[0].fr}}</div>
						<div v-if='item.mjid_from.length>1' class='p-col-4' style="padding: 0;">
							<Dropdown v-model="item.sl_mjid_from" :options="item.mjid_from" optionLabel="fr"
								placeholder="来源" />
						</div>
					</div>
					<!-- <div class='p-col-12 p-md-4'>来源</div> -->
				</div>

				<div class='card' style="margin-bottom:0px;">
					<div class="translation-item"
						style="font-weight: 700;padding-bottom: 5px;background-color:#f8f9fa;">
						<div class='p-col-4'>相似</div>
						<div class='p-col-4'>释义</div>
						<div class='p-col-4'>来源</div>
					</div>
					<div class='card-gray' style="margin-bottom:0px;">
						<div class="word-item translation-item"
							style="border-top:1px solid #ededed;padding: 2px 0px 2px 0px;"
							v-for="(item, j) of similar_words" :key="j">
							<div class='p-col-4' @click="searchWordItem(item)">{{item.w}}</div>
							<div class='p-col-4'>{{item.tr}}</div>
							<div v-if='item.mjid_from.length==1' class='p-col-4'>{{item.mjid_from[0].fr}}</div>
							<div v-if='item.mjid_from.length>1' class='p-col-4' style="padding: 0;">
								<Dropdown v-model="item.sl_mjid_from" :options="item.mjid_from" optionLabel="fr"
									placeholder="来源" />
							</div>
						</div>
					</div>
					<!-- <div class='p-col-12 p-md-4'>来源</div> -->
				</div>
			</div>
			<div class="card-gray" v-if="word_trans===false" id='para_and_translate' :style="trans_style"
				style="overflow-x:hidden;">
				<h6 style="vertical-align: middle;" @click="changeShowPara">{{show_paragraph?"收起":"展开"}}段落 (｡･∀･)ﾉﾞ of
					Page{{current_page}}<i v-if='false' class="pi pi-spin pi-spinner" style="fontSize: 0.5rem"></i><i
						class="pi pi-angle-down" style="float: right;"></i></h6>
				<div class="para-list" v-if='show_paragraph' style="width: 100%;">
					<div class="card" style="margin-top: 10px;margin-bottom: 10px;">
						<a href="https://zhuanlan.zhihu.com/p/572309700" target="_blank">
							<Button title='Chrome浏览器网页翻译功能已废,请换用 Edge 浏览器使用全文翻译功能' icon="pi pi-external-link"
								style="font-weight: 800;" label="请换 Edge 浏览器使用全文翻译功能"
								class="p-button-help mb-3 p-mr-2" />
						</a>
					</div>
					<div :style="para_container_style">
						<ul class="p-autocomplete-items" v-for="(item, j) of filter_para_list" :key="j">
							<i class="pi pi-book" style="float: right;margin-right: 0.80em;color: var(--primary-color)"
								@click="copyToClipboard(item,j)" title='复制当前段落文本'></i>
							<p class="word-item" :id="'trans_pa_'+j" @click="selectParaItem($event, item)"
								style="padding: 10px 3px 10px 8px;" v-html="item">
							</p>
						</ul>
					</div>
				</div>
				<div class='card' style="margin-bottom: 0px;">
					<h6 style="vertical-align: middle;" @click="changeShowTranslate">{{show_translate?"缩小":"放大"}}翻译卡<i
							v-if='!show_translate' class="pi pi-window-maximize" style="float: right;"></i><i
							class="pi pi-window-minimize" v-if='show_translate' style="float: right;"></i></h6>
					<!-- <i class='pi pi-window-maximize'></i> -->
					<div v-if='show_translate'>
						<Divider layout="horizontal" align="center">
							<span class="p-tag" @click="copyLink('trans_text')">译文
								<i class="pi pi-book" style="float: right;margin-left: 1em;margin-top: 2px;"
									title='复制'></i>
							</span>
						</Divider>
						<p contenteditable="true" style="padding: 3px;" id="trans_text">{{trans_text}}</p>
						<Divider layout="horizontal" align="center">
							<span class="p-tag" @click="copyLink('ori_text')">原文
								<i class="pi pi-book" style="float: right;margin-left: 1em;margin-top: 2px;"
									title='复制'></i>
							</span>
						</Divider>
						<p contenteditable="true" style="padding: 3px;" v-on:keyup.enter="searchSents" id="ori_text">
							{{ori_text}}
						</p>
						<Button label="翻译" @click="searchSents"></Button>
					</div>
				</div>
				<!-- 				<div class="card" style="margin-top: 10px;margin-bottom: 0px;">
					<a href="https://zhuanlan.zhihu.com/p/572309700" target="_blank">
						<Button title='通过修改系统 hosts 文件 100% 解决问题' icon="pi pi-external-link"
							style="font-weight: 800;" label="#必读#全文翻译失败并提示'无法翻译此页'的解决方案"
							class="p-button-help mb-3 p-mr-2" />
					</a>
				</div> -->
				<div class="card" style="margin-top: 10px;margin-bottom: 0px;">
					<h5>资源推荐</h5>
					<div>
						<span class="p-buttonset">
							<Button style="border-radius: 5px;width: 100%;" type="button" icon='pi pi-plus'
								title='关注科研Doge' label="关注科研Doge" @click="toggleDoge" class="p-button-warning p-mr-2" />
							<a target="_blank" href="https://mp.weixin.qq.com/s/-916GoUBuuixmYTTtmNCtw">
								<Button style="border-radius: 5px;" type="button" icon='pi pi-bell'
									title='重磅福利！留学申请模板及套磁速成教学视频免费送啦！！' label="免费福利" class="p-button-success p-mr-2" />
							</a>
						</span>
						<a target="_blank" title='重磅福利！留学申请模板及套磁速成教学视频免费送啦！'
							href="https://mp.weixin.qq.com/s/-916GoUBuuixmYTTtmNCtw">
							<img src="../../public/assets/layout/images/doge.png" style='width: 100%;margin-top: 10px;'
								alt="charge" />
						</a>
						<OverlayPanel ref="doge" appendTo="body" :showCloseIcon="true">
							<img src="../../public/assets/layout/images/doge-wx.jpg"
								style='width: 250px;border-radius: 10px;' alt="charge" />
						</OverlayPanel>
					</div>
					<div style="margin-top: 10px;">
						<a target="_blank" title='雅思听力语料库练习网站,一站搞定雅思听力,免费免登录可用!' href="http://practise.coolielts.com">
							<img src="../../public/assets/layout/images/cool-adv.png"
								style='width: 100%;' alt="charge" />
						</a>
					</div>
					<!-- 					<Panel header="优质公众号资源推荐" :toggleable="true">
						<div>
							<span class="p-buttonset">
								<Button style="border-radius: 5px;width: 100%;" type="button" icon='pi pi-plus'
									title='关注科研Doge' label="关注科研Doge" @click="toggleDoge"
									class="p-button-warning p-mr-2" />
								<a target="_blank" href="https://mp.weixin.qq.com/s/K9IfDMZ2_1qb4bm2wOs1jw">
									<Button style="border-radius: 5px;" type="button" icon='pi pi-bell'
										title='重磅福利！留学申请模板及套磁速成教学视频免费送啦！！' label="免费福利"
										class="p-button-success p-mr-2" />
								</a>
							</span>
							<img src="../../public/assets/layout/images/doge.png" style='width: 100%;margin-top: 10px;'
								alt="charge" />
							<OverlayPanel ref="doge" appendTo="body" :showCloseIcon="true">
								<img src="../../public/assets/layout/images/doge-wx.jpg"
									style='width: 250px;border-radius: 10px;' alt="charge" />
							</OverlayPanel>
						</div>
						<div>
							<a target="_blank" title='雅思听力语料库练习网站,一站搞定雅思听力,免费免登录可用!'
								href="http://practise.coolielts.com">
								<img src="../../public/assets/layout/images/cool-adv.png"
									style='width: 100%;margin-top: 10px;' alt="charge" />
							</a>
						</div>
					</Panel> -->
				</div>

				<!-- 				<div class="card" style="margin-top: 10px;margin-bottom: 0px;">
					<a href="https://mp.weixin.qq.com/s/uutCgLO8aVEOskqBPfhbcA" target="_blank">
						<Button title='做专业的学术研究，从拥有自己专业的学术词典开始！' icon="pi pi-external-link"
							style="font-weight: 800;" label="所有专业学术词典 Word 版开放获取!"
							class="p-button-info mb-3 p-mr-2" />
					</a>
				</div> -->
				<!-- 				<TabView>
					<TabPanel header="萤火虫翻译引擎">
						<i class='pi pi-window-maximize'></i>
						<Divider layout="horizontal" align="center">
							<span class="p-tag" @click="copyLink('trans_text')">译文 
							<i class="pi pi-book"
							style="float: right;margin-left: 1em;margin-top: 2px;" title='复制'></i>
							</span>
						</Divider>
						<p contenteditable="true" id="trans_text">{{trans_text}}</p>
						<Divider layout="horizontal" align="center">
							<span class="p-tag" @click="copyLink('ori_text')">原文
							<i class="pi pi-book"
							style="float: right;margin-left: 1em;margin-top: 2px;" title='复制'></i>
							</span>
						</Divider>
						<p contenteditable="true" v-on:keyup.enter="searchSents" id="ori_text">{{ori_text}}</p>
						<Button label="翻译" @click="searchSents"></Button>
					</TabPanel>
				</TabView> -->
			</div>

			<div class="card-gray">
				<div class="p-inputgroup">
					<span class="p-input-icon-right">
						<InputText id="para_search_input" autocomplete="off" style="width: 100%;" @focusin="paraFocusIn"
							type="text" @input="paraInputBlur" :placeholder="'选择段落 <(￣︶￣)> P'+current_page" />
						<i class="pi pi-times" style="opacity: 0.7;" @click.stop="InitialParas" title='清空输入框' />
					</span>
					<span class="p-inputgroup-addon" @click="clearPara">
						<i class="pi pi-times" title='清空输入框\原文框\翻译框'></i>
					</span>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	import TranslateService from '../service/TransLateService.js';
	import Utils from './Utils.vue';
	import FilterWordsService from '../service/FilterWordsService.js';
	import NetWorkService from '../service/NetWorkService.js';
	import EditWord from './userComponents/EditWord.vue';
	import pageNumInput from './userComponents/pageNumInput.vue';
	export default {
		emits: ['InitialWords', 'closeNoteEditor'],
		name: 'readPaper',
		components: {
			'EditWord': EditWord,
			'Utils': Utils,
			'pageNumInput': pageNumInput,
		},
		data() {
			return {
				whole_file: 0,
				show_translate: true,
				para_container_style: {
					"height": "290px",
					"overflow": "auto",
					"overflow-x": "hidden",
				},

				read_single_pdf: false,
				from_page_name: '',

				pdf_path: 'https://fireflycos.libertynlp.com/firefly-static/shouce_2022.pdf',
				// pdf_path: 'test.pdf',
				word: null,
				productDialog: false,
				product: null,
				pdf_name: null,

				from_personal: false, //是否是从用户表查询的结果
				search_from_dic: false,

				no_parse_page: [1], //未处理的页面
				page_content: [
					['LibertyNLP-firefly']
				], //先读取完所有文件再解码
				all_pages: 1,
				message: [],
				file_name: null,
				display: false,
				prosess_value: 0,
				//选择段落相关
				filter_para_list: [],
				current_page: null,
				current_page_sents: [],
				all_sents: [
					['LibertyNLP-firefly']
				],
				show_paragraph: false,

				//选择单词相关
				filter_list: [],
				similar_words: [],
				show_word_list: false,
				products: [],

				all_words: [],
				// select_word: '',
				select_word: '',
				word_trans: false,
				trans_style: {
					"overflow": "auto",
					"height": "auto",
				},
				trans_height: "auto",
				pdf_height: "505px",
				center_width: "",
				center_height: "",
				this_word: null,
				new_file: false,
				processing: false,
				check_time: 0,
				pdf_page: 1,
				value2: null,
				selectText: '',
				floatValue: null,
				select_value: '',
				ori_text: '',
				trans_text: null,
				pagesCount: 0,
				interval: null,
			}
		},
		translateService: null,
		created() {
			this.translateService = new TranslateService();
			this.filterWordsService = new FilterWordsService();
			this.networkService = new NetWorkService();
		},
		mounted() {
			let vm = this;
			//这是滑选事件
			// vm.getSelectText();
			vm.isMobile();
			vm.resizeWin();
			window.addEventListener('message', (e) => {
				// console.log(e.data);
				if (e.data.source != 'pdfjs') {
					return;
				}

				if (this.processing == true) {
					return;
				}
				this.processPdfjsMessage(e);
			});
			//屏幕变化时
			window.onresize = () => {
				return (() => {
					vm.resizeWin();
				})();
			};
			//测试知网翻译

		},
		beforeRouterLeave(to, from, next) {
			if (to.name === 'essayPolishing' || from.name === 'searchJournalIms') {
				to.meta.keepAlive = true
			}
			next()
		},
		beforeRouteEnter(to, from, next) {
			if (from.name === 'essayPolishing' || from.name === 'searchJournalIms' || from.name ===
				'managePapers') { // 处理页面缓存后，返回首页再次进入缓存页时数据为更新
				// 该生命周期无法获取到this，因此把vm实例当作参数传递
				next(vm => {
					vm.pdf_name = null;
					if (vm.$route.params.read_single_pdf == 0) {
						vm.pdf_path = vm.$route.params.pdf_path;
						// console.log('当前链接',vm.pdf_path);
						vm.read_single_pdf = true;
						vm.whole_file = 0;

						// console.log('图片页参数传到',vm.$route.params);

						vm.$refs.changeSinglePDF.total_page_num = parseFloat(vm.$route.params.total_page);
						vm.$refs.changeSinglePDF.current_page_num = parseFloat(vm.$route.params.current_page);
						vm.pdf_name = vm.$route.params.pdf_name;
						localStorage.setItem('current_anno_pdf_name', vm.$route.params.pdf_name);

						vm.searchAnnotation(vm.$route.params.pdf_path);
						vm.from_page_name = from.name;
						vm.viewerReset();
						vm.flushPdfPath(0);
					} else {
						// vm.viewerReset();
						if (vm.$route.params.pdf_path != null) {
							vm.pdf_path = vm.$route.params.pdf_path;
							vm.pdf_name = vm.$route.params.pdf_name;
							localStorage.setItem('current_anno_pdf_name', vm.$route.params.pdf_name);

							vm.searchAnnotation(vm.$route.params.pdf_path);
							vm.from_page_name = from.name;
							vm.whole_file = 1;
						} else {
							vm.whole_file = 0;
						}
						vm.read_single_pdf = false;
					}

				})
			} else {
				next(vm => {
					// vm.viewerReset();
					vm.read_single_pdf = false;
				})
			}
		},
		methods: {
			course_toggle(event) {
				this.$refs.course_op.toggle(event);
			},
			toggleDoge(event) {
				this.$refs.doge.toggle(event);
				// window.open('https://mp.weixin.qq.com/s/89NvKy5nKKPzA9EVReribQ', '_blank');
			},
			processPdfjsMessage(e) {
				var sentences = e.data;
				var content = sentences.content;
				//依据数据类型设置响应函数
				var type_d = sentences.type;
				switch (type_d) {
					case 0:
						this.file_name = content;
						this.viewerReset();
						break;
					case 1:
						this.changeCurrentPage(content);
						document.getElementById('para_and_translate').scrollTop = 0;
						break;
					case 2:
						var pdf_containerWindow = document.getElementById("pdf_container").contentWindow;
						// console.log('pdf_containerWindow',pdf_containerWindow);
						if (pdf_containerWindow == null) {
							return;
						}

						// console.log('页码',pdf_containerWindow.PDFViewerApplication.page);
						this.all_pages = pdf_containerWindow.PDFViewerApplication.pagesCount;
						var this_page = sentences.page; //当前PDF页码
						this.processSentences(content, this_page);
						break;
					case 3:
						this.updateAnnotation(content);
						// console.log('标注内容',content);
						break;
					case 4:
						this.$refs.Utils.copyHtmlToClipboard(content);
						// this.$refs.Utils.copyText(content);
						break;
					case 5:
						this.searchAnnotation(content);
						break;
					case 6:
						// console.log('收到内容',content);
						if (this.$appState.show_read_para == false || this.$appState.select_translate == false) return;
						this.word_trans = false;
						this.ori_text = content;
						this.select_word = null;
						this.translate(content);
						break;
					default:
						console.log('未指定相应函数');
				}
			},
			changeShowTranslate() {
				this.show_translate = !this.show_translate;
				if (this.show_translate == false) {
					this.show_paragraph = true;
					this.para_container_style.height = 'auto';
				} else {
					this.para_container_style.height = '290px';
				}
			},
			flushPdfPath(delta) {
				this.viewerReset(); //重置阅读文档页面
				this.pdf_path = this.$refs.Utils.getNeiborPage(this.pdf_path, delta);
			},
			//获取指定页码
			getTargetPage() {
				this.viewerReset(); //重置阅读文档页面
				this.pdf_path = this.$refs.Utils.getTargetPage(this.pdf_path, this.$refs.changeSinglePDF.current_page_num);
			},
			goBack() {
				// //跳转到阅读的页面
				console.log('返回来源页面');
				this.read_single_pdf = false;
				this.$router.push({
					name: this.from_page_name,
				})
				// this.$router.back();
			},
			//一键复制文本
			copyLink(explain_id) {
				// var explain_id='img_explain_'+j;
				var explain = document.getElementById(explain_id);
				window.getSelection().selectAllChildren(explain);
				document.execCommand("Copy");
				this.$toast.add({
					severity: 'success',
					summary: '复制提示',
					detail: '当前段落已复制到剪贴板',
					life: 2000
				});
			},
			//初始化单词编辑
			initialProduct() {
				this.product = null;
				this.productDialog = false;
				// console.log('当前单词已重置');
			},
			//单词编辑
			editWord() {
				if (this.word) {
					this.product = {
						...this.word
					};
					this.productDialog = true;
				}
			},
			//切换页码
			changeCurrentPage(content) {
				this.current_page = content;
				this.filter_para_list = this.all_sents[content];
				this.current_page_sents = this.all_sents[content];
			},
			//结果框滑动到最上面
			scrollToTop() {
				this.resizeWin();
				document.getElementById('word_container').scrollTop = 0;
			},
			showSuccess() {
				this.$refs.Utils.showPdfParseSuccess();
				// console.log('选择成功');
			},
			close() {
				this.processing = false;
				this.new_file = false;
				this.display = false;
				if (this.prosess_value < 100) {
					//将所有页面划入未处理
					var all_pages = [];
					for (var i = 1; i <= this.all_pages; i++) {
						all_pages.push(i);
					}
					this.no_parse_page = all_pages;
					this.$refs.Utils.showPdfParseFailed();
				}
			},
			//定义是否展示单词列表
			changeShowWord() {
				this.show_word_list = !this.show_word_list;
				this.filter_list = this.all_words;
			},
			//监听单词输入框
			InputBlur() {
				var word_input = document.getElementById("search_input");
				var keyword = word_input.value;
				if (!keyword) {
					this.InitialWords();
					return;
				}
				this.select_word = keyword;
				var words = this.select_word.split(' ');
				var new_key = '';
				if (words.length == 1) {
					new_key = this.select_word;
				} else {
					new_key = words[words.length - 1]
				}
				this.filter_list = this.filterWordsService.highLightWord(new_key, this.all_words);
			},
			//选择单词对象
			selectItem(event, item) {
				var new_select_word = item.replace(/<.*?>/ig, ""); //去除高亮标签
				if (!this.select_word) {
					this.select_word = new_select_word + ' ';
				} else {
					var words = this.select_word.split(' ');
					words[words.length - 1] = new_select_word + ' ';
					this.select_word = words.join(' ');
				}
				document.getElementById('search_input').focus();
				this.searchWord(); //选中就发起搜索
			},
			InitialWords() {
				this.select_word = '';
				this.filter_list = this.all_words;
				document.getElementById("search_input").value = '';
			},
			focusIn() {
				this.word_trans = true;
				if (this.show_word_list == false && this.all_words.length > 1) {
					this.filter_list = this.all_words;
					this.show_word_list = true;
				}
			},
			//段落操作
			selectParaItem(event, item) {
				if (this.show_translate == false) {
					return;
				}

				var new_select_word = item.replace(/<.*?>/ig, "");
				//刷新选择的段落列表
				// this.filter_para_list = this.all_sents[this.current_page];
				this.ori_text += new_select_word + '\r';
				document.getElementById('para_search_input').focus();
				document.getElementById("ori_text").innerText = new_select_word + '\r';
				this.searchSents();
			},
			InitialParas() {
				this.para_select_word = '';
				this.filter_para_list = this.all_sents[this.current_page];
				document.getElementById("para_search_input").value = '';
			},
			changeShowPara() {
				this.show_paragraph = !this.show_paragraph;
				if (this.show_paragraph) {
					this.current_page_sents = this.all_sents[this.current_page];
					this.filter_para_list = this.current_page_sents;
				}
			},
			paraInputBlur() {
				var word_input = document.getElementById("para_search_input");
				var keyword = word_input.value;
				if (!keyword) {
					this.InitialParas();
					return;
				}
				this.para_select_word = keyword;
				this.filter_para_list = this.filterWordsService.highLightWord(keyword, this.current_page_sents);
			},
			paraFocusIn() {
				this.word_trans = false;
				if (this.show_paragraph == false) {
					this.current_page_sents = this.all_sents[this.current_page];
					this.filter_para_list = this.current_page_sents;
					this.show_paragraph = true;
				}
			},
			clearPara() {
				document.getElementById("ori_text").innerText = '';
				this.ori_text = '';
				document.getElementById("trans_text").innerText = '';
				this.trans_text = '';
				this.InitialParas();
			},
			searchWord() {
				//去除最后一个单词结尾的空格
				var words = this.select_word.split(' ');
				if (!words[words.length - 1]) {
					words.splice(words.length - 1, 1)
				} //删除最后一个元素，也就是空格
				var word = words.join(' ');

				this.word_trans = true;
				// this.translateCNKI(word);
				this.translateWordsByLocal(word);
			},
			searchSents() {
				let sentents = document.getElementById("ori_text").innerText;
				this.translate(sentents);
			},
			// 判断设备是什么类型:pc,phone,pad
			isMobile() {
				this.flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				this.current_page = 1;
				if (this.flag === null) {
					// console.log("pc端");
					this.rightNavShow = true;
					this.resizeWin();
				} else {
					this.pdf_height = "505px";
					this.trans_style.height = "auto";
				}
			},
			//获取滑动获取的内容
			getSelectText() {
				var that = this;
				var iframe = document.getElementById('pdf_container');
				let x = '';
				let y = '';
				let _x = '';
				let _y = '';
				iframe.onload = function() {
					// 鼠标点击监听
					iframe.contentDocument.addEventListener('mousedown', function(e) {
						x = e.pageX;
						y = e.pageY;
						// console.log('开始x',x);
						// console.log('开始y',y);
					}, true);
					//鼠标抬起监听
					iframe.contentDocument.addEventListener('mouseup', function(e) {
						if (that.show_translate == false) {
							return;
						}

						_x = e.pageX;
						_y = e.pageY;
						// console.log('终结x',_x);
						// console.log('终结y',_y);
						if (x == _x && y == _y) return; //判断点击和抬起的位置，如果相同，则视为没有滑选，不支持双击选中
						var choose = iframe.getSelection().toString();
						// console.log('选中的范围',iframe.contentWindow.getSelection().getRangeAt(0));
						// var choose = iframe.contentWindow.getSelection().baseNode.textContent;
						// var choose = iframe.contentWindow.getSelection();

						//全部作为段落翻译
						that.word_trans = false;
						that.ori_text = choose;
						that.select_word = null;
						that.translate(choose);

					}, true);
				};
			},
			//翻译段落
			async translate(content) {
				this.$appState.processing = true;
				var resp = await this.$refs.Utils.translateSents(content);
				this.trans_text = resp.data.translate_text;
				this.$appState.processing = false;
			},
			//查询类似单词
			searchWordItem(item) {
				this.select_word = item.w;
				this.translateWordsByLocal(item.w);
			},
			//从字典表中查询单词
			searchFromDics() {
				this.search_from_dic = true;
				this.searchWord();
			},
			//本地词库查询
			async translateWordsByLocal(content) {
				// console.log("请求开始");
				this.$appState.processing = true;
				var resp = await this.$refs.Utils.searchWordAndPhrase(content, this.search_from_dic);
				if (resp.code == 200) {
					this.products = resp.data.words.tl;
					this.word = resp.data.words;
					this.from_personal = resp.data.from_personal;
					this.similar_words = resp.data.similar_words;
					this.search_from_dic = false;
					this.scrollToTop();
					//更新数量
					this.$appState.new_words_num = resp.data.unsaved_count;
				}
				this.processing = false;
				this.$appState.processing = false;
			},
			//屏幕变化调整样式
			resizeWin() {
				this.center_width = document.documentElement.scrollWidth;
				if (this.center_width <= 750) {
					this.pdf_height = "465px";
					this.trans_style.height = "auto";
				} else {
					var windowHeight = document.documentElement.clientHeight;
					if (windowHeight <= 800) {
						this.pdf_height = "700px";
						this.trans_style.height = "590px";
					} else {
						this.pdf_height = windowHeight - 148 + 'px';
						this.trans_style.height = windowHeight - 258 + 'px';
					}
				}
			},
			//打开新文件后重置页面
			viewerReset() {
				this.no_parse_page = [1],
					this.page_content = [],
					this.new_file = true;
				this.all_pages = 1;
				this.current_page = 1;
				this.check_time = 0;
				this.pdf_page = 1;
				this.display = false;
				this.prosess_value = 0;

				this.show_word_list = false;
				this.show_paragraph = false;

				this.all_words = [];
				this.all_sents = [
					['libertynlp']
				];

				var my_input = document.getElementById("search_input");
				my_input.value = '';

				// var iframe = document.getElementById('pdf_container');
				// iframe.contentWindow.PDFViewerApplication.findBar.close();

			},
			processSentences(sens, this_page) {
				//正在处理时返回
				if (this.processing == true) {
					return;
				}
				this.processing = true;
				this.$appState.processing = true;
				var sents = sens.join(' ');

				this.all_words = this.filterWordsService.joinWordlist(this.all_words, sents); //拼接单词表
				this.new_sents = this.filterWordsService.splitSents(sents); //拼接单词表
				this.all_sents[this_page] = this.new_sents;
				this.changeCurrentPage(this.current_page);
				this.processing = false;
				this.$appState.processing = false;
			},
			copyToClipboard(this_or_text, j) {
				if (this_or_text.slice(0, 2) == '  ') {
					var or_text = this_or_text.slice(2, this_or_text.length);
				} else {
					or_text = this_or_text;
				}
				var trans_text = document.getElementById("trans_pa_" + j).innerText;
				var text = '';
				if (this.$appState.copy_radio_value == 'ori_text') {
					text = or_text;
				} else if (this.$appState.copy_radio_value == 'trans_text') {
					text = trans_text;
				} else {
					if (or_text == trans_text) {
						text = or_text;
					} else {
						text = or_text + '\n' + trans_text;
					}
				}
				this.$refs.Utils.copyToClipboard(text);
			},
			async updateAnnotation(anno_data) {
				var resp = await this.$refs.Utils.updateAnnotatiaon(anno_data);
				if (resp.code == 200) {
					this.$refs.Utils.showSusscessInfo(resp['msg']);
				}
				// console.log('返回结果',resp);
			},
			async searchAnnotation(pdf_url) {
				var resp = await this.$refs.Utils.searchAnnotation(pdf_url);
				if (resp.code == 200) {
					if (resp.annos_info.length == 0) {
						this.$refs.Utils.showSusscessInfo('加载标注成功,标注为0');
						return;
					}

					var anno_contents = resp.annos_info[0]['anno_content'];
					for (var i = 0; i < anno_contents.length; i++) {
						var annotation_id = pdf_url + '_page_' + (i + 1).toString();
						var content = JSON.stringify(anno_contents[i]);
						localStorage.setItem(annotation_id, content);
					}
					if (this.$appState.show_read_para == true) {
						this.$refs.pdfjs_viewer_1.contentWindow.refreshCanvas();
					} else {
						this.$refs.pdfjs_viewer_2.contentWindow.refreshCanvas();
					}
					this.$refs.Utils.showSusscessInfo('加载标注成功');
				}
				// console.log('返回结果',resp.annos_info[0]['anno_content']);
			},
		},
		computed: {
			show_para() {
				return this.$appState.show_read_para;
			},
		}
	}
</script>

<style scoped lang="scss">
	::v-deep(.p-multiselect) {
		min-width: 15rem;
	}

	.multiselect-custom-virtual-scroll .p-multiselect {
		min-width: 20rem;
	}

	::v-deep(.multiselect-custom .p-multiselect-label) {
		padding-top: .25rem;
		padding-bottom: .25rem;
	}

	.multiselect-custom .country-item.country-item-value {
		padding: .25rem .5rem;
		border-radius: 3px;
		display: inline-flex;
		margin-right: .5rem;
		background-color: var(--primary-color);
		color: var(--primary-color-text);
	}

	.multiselect-custom .country-item.country-item-value span.flag {
		width: 17px;
	}

	.country-item {
		display: flex;
		align-items: center;
	}

	.country-item span.flag {
		width: 18px;
		height: 12px;
		margin-right: .5rem;
	}

	.multiselect-custom .country-placeholder {
		padding: 0.25rem;
	}

	.word-item {}

	.word-item:hover {
		background-color: #1fa1fc;
		color: #ffffff;
	}

	.translation-item {
		display: flex;
		flex-direction: row;
	}

	.title-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
</style>